import { Component, OnInit, ViewChild } from '@angular/core';
import * as L from 'leaflet';
import { Polyline, Marker } from 'leaflet';
import { LayersDefinition } from './layers';
import { HttpClient } from '@angular/common/http';
import { parse } from 'node-html-parser';
import { ActivatedRoute } from '@angular/router';
import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { of as observableOf, merge as observableMerge, Observable } from 'rxjs';
import { catchError, switchMap, startWith } from 'rxjs/operators';

@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss'],
})
export class MapComponent implements OnInit {
  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;

  displayedColumns = ['name', 'delay'];
  map: L.Map;
  nbLoadedData = 0;
  icons = new Map();
  logMsg = '';
  spreadsheet = '';
  options = { zoom: 11, center: L.latLng([48.398, -4.465]) };
  baseLayers = {};
  dataSource = new MatTableDataSource();
  dao: TableDAO | null;
  selectedMarker: Marker;
  mapIdMarker = new Map();
  selectedRowIndex = -1;
  constructor(
    private layersDef: LayersDefinition,
    private http: HttpClient,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.spreadsheet = params.sheet;
    });
    this.baseLayers = this.layersDef.getLayers();
  }
  ngAfterViewInit() {
    this.paginator.pageIndex = 0;
    this.paginator.length = 0;
    this.dataSource.sort = this.sort;
    this.sort.sortChange.subscribe(() => this.paginator.pageIndex = 0);
    this.dao = new TableDAO(this.http, this.spreadsheet);
    this.dataSource.paginator = this.paginator;
    const allDatas: UdafData[] = [];
    this.http
      .get('https://docs.google.com/spreadsheets/d/' + this.spreadsheet, {
        responseType: 'text',
      })
      .subscribe((data) => {
        const root = parse(data);
        const table = root.querySelector('table');
        for (let i = 2; i < table.childNodes[1].childNodes.length; i++) {
          const elt = table.childNodes[1].childNodes[i];
          const coordchild = elt.childNodes[5];
          if (coordchild.text.length > 0) {
            let coords = coordchild.text.split(',');
            let d = new UdafData();
            d.id = i - 2;
            d.name = elt.childNodes[1].text;
            d.lati = Number(coords[0]);
            d.longi = Number(coords[1]);
            d.delay = Number(elt.childNodes[6].text);
            d.count = elt.childNodes[8].text;
            d.last = elt.childNodes[7].text;
            const classname = elt.childNodes[7]['classNames'];
            let idx = data.indexOf('.ritz .waffle .' + classname);
            let idx2 = data.indexOf('background-color:#', idx);
            d.color = data.substring(idx2 + 18, idx2 + 24);
            if (!d.color) {
              console.log(
                'Couleur incorrecte pour ' +
                elt.childNodes[1].text +
                ' classe:' +
                classname[0]
              );
            }
            allDatas.push(d);
          } else if (elt.childNodes[1].text.length > 0) {
            console.log('Création impossible de ' + elt.childNodes[1].text);
          }
        }
        this.dao.setData(allDatas);
        this.refreshTable();
      });
  }

  refreshTable() {
    this.dataSource.data = [];
    observableMerge()
      .pipe(
        startWith(null),
        switchMap(() => {
          return this.dao!.getData();
        }),
        catchError(() => {
          return observableOf([]);
        })
      )
      .subscribe((data: UdafData[]) => {
        this.dataSource.data = data;
        this.paginator.pageIndex = 0;
        this.paginator.length = data.length;
        this.mapIdMarker.clear();


        const udafIcon = L.icon({
          iconUrl: this.generateWorkIconUrl("#0000FA"),
          iconAnchor: [10, 33],
        });
        L.marker(L.latLng(48.4298284, -4.460705), {
          icon: udafIcon,
        }).addTo(this.map);


        const jailIcon = L.icon({
          iconUrl: this.generateJailIconUrl("#0000FA"),
          iconAnchor: [10, 33],
        });
        L.marker(L.latLng(48.42106300008736, -4.475893496286761), {
          icon: jailIcon,
        }).addTo(this.map);

        if (this.dataSource.data.length > 0) {
          for (let i = 0; i < this.dataSource.data.length; i++) {
            let adata = this.dataSource.data[i] as UdafData;
            let myIcon = this.icons.get(adata.color);
            if (!myIcon) {
              myIcon = L.icon({
                iconUrl: this.generateIconUrl("#" + adata.color),
                iconAnchor: [10, 33],
              });
              this.icons.set(adata.color, myIcon);
            }
            const marker = L.marker(L.latLng(adata.lati, adata.longi), {
              icon: myIcon,
            });
            marker.bindTooltip(
              "<div style='background:#" +
              adata.color +
              ";'><b>" +
              adata.name +
              '<br>Dernière visite: ' +
              adata.last +
              '<br>Cette année: ' +
              adata.count +
              '</b></div>',
              {
                //permanent: true,
                direction: 'right',
              }
            );
            marker.addTo(this.map);
            this.mapIdMarker.set(adata.id, marker);
          }
          this.nbLoadedData = this.dataSource.data.length;
          console.log('NB données:' + this.nbLoadedData);
        }
      });
  }
  generateIconUrl(color: string): string {
    const svgTemplate = `
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="33" fill="${color}"  viewBox="0 0 20 33"><path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z"/></svg>
    `;
    const base64Icon = btoa(svgTemplate);
    return `data:image/svg+xml;base64,${base64Icon}`;
  }

  generateJailIconUrl(color: string): string {
    const svgTemplate = `
    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="33" fill="${color}" viewBox="0 0 20 33"><path d="M20.377 11.082c-.06 1.929-2.229 3.126-8.409 3.126-6.193 0-8.358-1.203-8.409-3.139 1.508 0 4.379-1.958 8.409-1.958 3.927-.001 7.144 1.971 8.409 1.971zm-8.408 4.09c-2.062 0-3.74-.131-5.078-.397.062.555.469 3.322 2.409 3.322 1.721 0 1.673-1.316 2.721-1.316 1.047 0 1.169 1.316 2.852 1.316 2.09 0 2.46-3.063 2.494-3.389-1.387.311-3.169.464-5.398.464zm6.405-.741c-.04 2.171-.717 4.769-2.28 6.437-1.048 1.119-2.377 1.687-3.949 1.687-1.575 0-2.898-.533-3.931-1.582-1.646-1.673-2.302-4.345-2.396-6.461-.523-.158-1.01-.347-1.484-.628-.016 2.472.704 5.942 2.821 8.094 1.321 1.341 3 2.022 4.99 2.022 1.972 0 3.712-.745 5.033-2.153 2.131-2.273 2.76-5.679 2.661-8.111-.459.308-.944.521-1.465.695zm-6.237-10.984l-.313.623-.701.1.507.485-.119.685.626-.324.627.324-.12-.685.507-.485-.7-.1-.314-.623zm7.211-.206s-2.537-.686-7.348-3.241c-4.812 2.555-7.348 3.241-7.348 3.241s-1.295 2.4-3.652 5.016l2.266 1.908c1.533-.165 4.64-2.082 8.734-2.082s7.201 1.917 8.734 2.083l2.266-1.909c-2.357-2.616-3.652-5.016-3.652-5.016zm-6.345 3.214c-.526.131-.605.188-.875.402-.269-.214-.349-.271-.875-.402-.731-.183-1.151-.656-1.151-1.299 0-.359.147-.691.318-1.146.192-.513.083-.675-.119-.882l-.171-.176.987-.819c.098.098.235.278.486.278.248 0 .416-.175.528-.271.102.09.268.271.523.271.248 0 .381-.171.49-.281l.983.823-.172.176c-.202.207-.311.369-.119.882.17.455.318.786.318 1.146 0 .641-.42 1.115-1.151 1.298z"/></svg> `;
    const base64Icon = btoa(svgTemplate);
    return `data:image/svg+xml;base64,${base64Icon}`;
  }


  generateWorkIconUrl(color: string): string {
    const svgTemplate = `
    <svg width="20" height="33" xmlns="http://www.w3.org/2000/svg" fill="${color}" ><path d="M22 22h1v2h-22v-2h1v-8h20v8zm-7-5h-6v6h2v-5h2v5h2v-6zm-9 3h-2v2h2v-2zm14 0h-2v2h2v-2zm-14-4h-2v2h2v-2zm14 0h-2v2h2v-2zm4-3h-24l3-7h3.943l-2.601 2.229.66.771 5.998-5.143v-3.857h5l-1 1.491 1 1.509h-4l6.999 6 .661-.771-2.602-2.229h3.942l3 7zm-12-6.5c1.38 0 2.5 1.12 2.5 2.5s-1.12 2.5-2.5 2.5-2.5-1.12-2.5-2.5 1.12-2.5 2.5-2.5zm0 2.5h1v.8h-1.763v-1.8h.763v1z"/></svg>
    `;
    const base64Icon = btoa(svgTemplate);
    return `data:image/svg+xml;base64,${base64Icon}`;
  }

  onMapReady(map: L.Map) {
    setTimeout(() => {
      this.map = map;
      // let circle = L.circle([48.432, -4.342], { radius: 5000 });
      // circle.addTo(this.map);
      // this.map.fitBounds(circle.getBounds());
      map.invalidateSize();
    }, 0);
  }

  ngOnDestroy() {
    this.map.remove();
  }

  setSelectedRow(id: number) {
    this.selectedRowIndex = id;
    if (this.selectedMarker) {
      const icon = this.selectedMarker.options.icon;
      icon.options.iconAnchor = [10, 33];
      icon.options.iconSize = [21, 34];
      this.selectedMarker.setIcon(icon);
    }
    this.selectedMarker = this.mapIdMarker.get(id);
    const icon = this.selectedMarker.options.icon;
    icon.options.iconAnchor = [21, 67];
    icon.options.iconSize = [42, 68];
    this.selectedMarker.setIcon(icon);
  }
}

export class TableDAO {
  allDatas: UdafData[] = [];
  constructor(private http: HttpClient, private spreadsheet: string) { }

  setData(datas: UdafData[]) {
    this.allDatas = datas;
  }

  getData(): Observable<UdafData[]> {
    return observableOf(this.allDatas);
  }
}

export class UdafData {
  id: number;
  name: string;
  lati: number;
  longi: number;
  delay: number;
  count: string;
  last: string;
  color: string;
}
