<div class="container">
  <div fxLayout="row" fxLayout.xs="column" fxLayoutWrap fxLayoutGap="25px">
    <div fxFlex="calc(70%-25px)" class="map-container" leaflet [leafletOptions]="options" [leafletBaseLayers]="baseLayers" (leafletMapReady)="onMapReady($event)" flex></div>

    <div fxFlex="calc(30%-25px)">
      <mat-table #table [dataSource]="dataSource" matSort matSortActive="delay" matSortDirection="desc"  flex layout="row" layout-fill>

        <ng-container matColumnDef="name">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Nom </mat-header-cell>
          <mat-cell *matCellDef="let row; let element" [style.background-color]="'#' + row.color" [ngClass]="{'highlight': selectedRowIndex == row.id}"> {{element.name}} </mat-cell>
        </ng-container>
        <ng-container matColumnDef="delay">
          <mat-header-cell *matHeaderCellDef mat-sort-header> Depuis </mat-header-cell>
          <mat-cell *matCellDef="let row; let element" [style.background-color]="'#' + row.color" [ngClass]="{'highlight': selectedRowIndex == row.id}"> {{element.delay}} </mat-cell>
        </ng-container>


        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;" (click)="setSelectedRow(row.id)" [ngClass]="{'highlight': selectedRowIndex == row.id}">
        </mat-row>
      </mat-table>
      <mat-paginator #paginator [pageSize]="15" [pageIndex]="0" [pageSizeOptions]="[10, 15, 20]">
      </mat-paginator>
    </div>
  </div>
</div>
